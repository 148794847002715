<script setup lang="ts">
import { ref, computed, watch, withDefaults, defineProps, defineEmits } from "vue";

// Interface des props
interface Props {
  modelValue: string; // Pour le v-model
  required?: boolean;
  label?: string;
  dateMin?: string;
  variant?: string;
}

// Props avec valeurs par défaut
const props = withDefaults(defineProps<Props>(), {
  required: false,
  label: "",
  dateMin: "1920-01-01",
  variant: '',
});

// ID unique pour le champ
const uniqueId = `field-${Math.random().toString(36).substr(2, 9)}`;

// Gestion de la valeur interne et des erreurs
const internalValue = ref(props.modelValue); // Synchro avec v-model
const errorMessage = ref<string | null>(null);

// Propriété calculée pour le label
const computedLabel = computed(() => {
  return props.label ? `${props.label}${props.required ? " *" : ""}` : "";
});

// Validation personnalisée
const validate = (value: string) => {
  if (props.required && (!value || value.trim() === "")) {
    return "Ce champ est requis.";
  }
  if (value && value < props.dateMin!) {
    return `La date doit être après ${props.dateMin}.`;
  }
  if (value && value > props.dateMax!) {
    return `La date doit être avant ${props.dateMax}.`;
  }
  return null;
};

// Émettre les changements de valeur vers le parent
const emit = defineEmits<{ (event: "update:modelValue", value: string): void }>();

// Réagir aux changements internes et valider
watch(internalValue, (newValue) => {
  errorMessage.value = validate(newValue);
  emit("update:modelValue", newValue);
});
</script>

<template>
  <div>
    <v-text-field
      density="compact"  
      v-model="internalValue"
      type="date"
      :min="dateMin"
      :name="uniqueId"
      :variant="variant"
      v-bind="$attrs"
      :error-messages="errorMessage"
      :label="computedLabel"
      @update:modelValue="$emit('update:modelValue', $event)" />
  </div>
</template>
